import { styledComponent, Button, Card, Box } from '@hermes/web-components'

export const StyledButton = styledComponent(Button)<{
  buttonBackground: string
}>(({ theme, buttonBackground }) => ({
  background: buttonBackground,
  borderRadius: '24px',
  [theme.breakpoints.down('sm')]: {
    padding: '6px 12px',
    fontSize: '13px',
    lineHeight: '19px'
  }
}))

export const ReviewCardContainer = styledComponent(Card)(({ theme }) => ({
  borderRadius: '16px !important',
  border: `1px solid ${theme.palette.grey[600]}`
}))

export const ReviewOverviewContainer = styledComponent(Box)({
  contentVisibility: 'auto',
  containIntrinsicHeight: '480px',
  minHeight: '480px'
})
