import { Grid } from '@hermes/web-components'
import { ReviewsAlert } from '../BasicProfileAlert'

type BasicProfileReviewsProps = {
  name: string
  isProOrExpertPro?: boolean
  isEntry?: boolean
}

const BasicProfileReviews = ({ name, isProOrExpertPro, isEntry }: BasicProfileReviewsProps) => (
  <Grid sx={{ mt: 5 }}>
    <ReviewsAlert name={name} isProOrExpertPro={isProOrExpertPro} isEntry={isEntry} bordered />
  </Grid>
)

export default BasicProfileReviews
